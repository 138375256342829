import {
  ContractContactProjectionDtoRoleEnum,
  ContractProjectionDto,
} from 'api/accounting';
import _ from 'lodash';
import { MessageSendingDocument } from 'pages/MessageSendingPage/interfaces';

export const buildDocumentRecipients = (documentId: number, contracts: ContractProjectionDto[]): MessageSendingDocument => {
  const searchParams = {
    documentId,
    recipients: [],
  };

  if (!_.isEmpty(contracts)) {
    searchParams.recipients = contracts
      .filter(c => !c?.isVacant)
      .flatMap(c => c?.contacts
        .filter(cc => !_.isEqual(cc.role, [ContractContactProjectionDtoRoleEnum.RESIDENT])
          && !_.isEqual(cc.role, [ContractContactProjectionDtoRoleEnum.SUBTENANT])
          && !_.isEqual(cc.role, [ContractContactProjectionDtoRoleEnum.RESIDENT, ContractContactProjectionDtoRoleEnum.SUBTENANT]))
        .map(cc => ({
          contractId: c?.unitContractId,
          contactId: cc?.contactId,
        })));
  }

  return searchParams;
};
